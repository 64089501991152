export default {
  "600": {
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    "@media screen and (min-width: 64em)": {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  // Default
  "500": {
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    "@media screen and (min-width: 64em)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "400": {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    "@media screen and (min-width: 64em)": {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  "300": {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "16px",
    letterSpacing: "-0.01em",
    "@media screen and (min-width: 64em)": {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
}
