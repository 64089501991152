import { rgba } from "polished"

import colors from "./colors"

const borderShadowColor = rgba(colors.text, 0)
const blurryShadowColor = rgba(colors.text, 0.3)

/**
 * Elevation styles are applied as box shadows.
 * Available levels: 0, 1, 2, 3, 4.
 */

export default [
  `0 0 0 1px inset ${borderShadowColor}`,
  `0 0 0 1px inset ${borderShadowColor}, 0 2px 4px -2px ${blurryShadowColor}`,
  `0 0 0 1px inset ${borderShadowColor}, 0 5px 8px -4px ${blurryShadowColor}`,
  `0 0 0 1px inset ${borderShadowColor}, 0 8px 10px -4px ${blurryShadowColor}`,
  `0 0 0 1px inset ${borderShadowColor}, 0 16px 24px -8px ${blurryShadowColor}`,
]
