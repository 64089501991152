export default {
  "900": {
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "32px",
    letterSpacing: "-0.03em",
    "@media screen and (min-width: 64em)": {
      fontSize: "64px",
      lineHeight: "64px",
    },
  },
  "800": {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
    "@media screen and (min-width: 64em)": {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  // Default
  "700": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
    letterSpacing: "-0.03em",
    "@media screen and (min-width: 64em)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "600": {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
    "@media screen and (min-width: 64em)": {
      fontSize: "36px",
      lineHeight: "40px",
    },
  },
  "500": {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
    "@media screen and (min-width: 64em)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "400": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
    textTransform: "uppercase",
    letterSpacing: "-0.05em",
    "@media screen and (min-width: 64em)": {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  "300": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
    textTransform: "uppercase",
    letterSpacing: "-0.025em",
    "@media screen and (min-width: 64em)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "200": {
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "16px",
    textTransform: "uppercase",
    letterSpacing: "-0.025em",
    "@media screen and (min-width: 64em)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  "100": {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    "@media screen and (min-width: 64em)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
}
