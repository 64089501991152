import React, { useState, useEffect, useCallback } from "react"
import { motion, useViewportScroll } from "framer-motion"
import { useSpring, animated } from "react-spring"
import css from "@styled-system/css"

function Stat({
  delay,
  duration,
  delayInitialCheck,
  children,
  direction,
  prefix,
  value,
  suffix,
}) {
  const [sigDigs] = useState(() => {
    if (value.toString().indexOf(".") !== -1) {
      return value.toString().length - 1
    } else {
      return value.toString().length
    }
  })
  const [show, setShow] = useState(false)
  const [wrapper, setWrapper] = useState(undefined)
  const measuredRef = useCallback(node => {
    setTimeout(() => {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      if (node !== null) {
        if (node.getBoundingClientRect().top < height) {
          setShow(true)
        } else {
          setWrapper(node)
        }
      }
    }, delayInitialCheck)
  }, [])
  const { scrollY } = useViewportScroll()

  useEffect(() => {
    if (wrapper) {
      scrollY.onChange(v => {
        const height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight
        if (
          wrapper.getBoundingClientRect().top < height - height / 3 &&
          !show
        ) {
          setShow(true)
        }
      })
    }
  }, [wrapper])

  const childrenWithProps = React.Children.map(children, (child, i) =>
    React.cloneElement(child, { show: show })
  )

  const counter = useSpring({
    value: show
      ? parseFloat(value)
      : direction === -1
      ? parseFloat(value) * 3
      : 0,
  })

  return (
    <span ref={measuredRef} data-component-id="stat">
      <motion.span
        variants={{
          initial: {
            opacity: 0,
          },
          enter: {
            opacity: 1,
            transition: {
              duration: duration,
              delay: delay,
              when: "beforeChildren",
              staggerChildren: 0.2,
            },
          },
        }}
        initial="initial"
        animate={show ? "enter" : "initial"}
      >
        {prefix}
        <animated.span>
          {counter.value.interpolate(val =>
            Number(val.toPrecision(sigDigs)).toLocaleString()
          )}
        </animated.span>
        <span css={css({ display: "block" })}>{suffix}</span>
      </motion.span>
    </span>
  )
}

Stat.defaultProps = {
  delay: 0,
  delayInitialCheck: 1100,
  duration: 0.4,
  direction: 1,
}

export default Stat
