import { rgba } from "polished"

export default {
  text: "#151515",
  alt: rgba("#151515", 0.5),
  background: "#fff",
  wash: rgba("#151515", 0.05),
  border: rgba("#151515", 0.2),
  brand: {
    primary: "#00C7EA",
    secondary: "#8BC953",
    tertiary: "#939497",
  },
  modes: {
    dark: {
      text: "#fff",
      alt: rgba("#fff", 0.7),
      background: "#151515",
      wash: rgba("#fff", 0.2),
      border: rgba("#fff", 0.2),
      brand: {
        primary: "#00C7EA",
        secondary: "#8BC953",
        tertiary: "#939497",
      },
    },
  },
}
