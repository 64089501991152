import React from "react"
import { nanoid } from "nanoid"

import { Box } from "../../box"
import {
  FormFieldDescription,
  FormFieldHint,
  FormFieldLabel,
  FormFieldValidationMessage,
} from "../../formField"

import Textarea from "./Textarea"

const FormFieldTextarea = ({
  description,
  hint,
  label,
  textareaProps,
  validationMessage,
  ...rest
}) => {
  const uid = nanoid()

  return (
    <Box data-fresco-id="formFieldTextarea" {...rest}>
      {label && <FormFieldLabel label={label} />}
      {description && <FormFieldDescription description={description} />}
      <Textarea id={uid} {...textareaProps} />
      {hint && <FormFieldHint hint={hint} />}
      {validationMessage && (
        <FormFieldValidationMessage validationMessage={validationMessage} />
      )}
    </Box>
  )
}

export default FormFieldTextarea
