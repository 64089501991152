import React from "react"
import { Link } from "gatsby"
import { mdiArrowTopRight } from "@mdi/js"

import { Avatar, Box, ColorMode, Flex, Heading, Icon } from "components"

const ServicePill = ({ color, image, title, to }) => (
  <ColorMode mode="dark">
    <Box data-component-id="servicePill">
      <Flex
        as={Link}
        to={to}
        alignItems="center"
        height={[42, null, null, 64]}
        p={["spacing.2", null, null, "spacing.3"]}
        borderRadius={9999}
        bg={color}
        boxShadow="elevations.2"
      >
        <Avatar size={[32, null, null, 48]} bg="wash" image={image} />
        <Heading
          size={800}
          mx={["spacing.3", null, null, "spacing.4"]}
          pb="spacing.2"
        >
          {title}
        </Heading>
        <Icon
          symbol={mdiArrowTopRight}
          size={[16, null, null, 24]}
          mr={["spacing.3", null, null, "spacing.4"]}
          color="text"
        />
      </Flex>
    </Box>
  </ColorMode>
)

export default ServicePill
