import React from "react"
import { nanoid } from "nanoid"

import { Box } from "../../box"
import {
  FormFieldDescription,
  FormFieldHint,
  FormFieldLabel,
  FormFieldValidationMessage,
} from "../../formField"

import Select from "./Select"

const FormFieldSelect = ({
  description,
  hint,
  selectProps,
  label,
  validationMessage,
  ...rest
}) => {
  const uid = nanoid()

  return (
    <Box data-component-id="formFieldSelect" {...rest}>
      {label && <FormFieldLabel label={label} />}
      {description && <FormFieldDescription description={description} />}
      <Select id={uid} {...selectProps} />
      {hint && <FormFieldHint hint={hint} />}
      {validationMessage && (
        <FormFieldValidationMessage validationMessage={validationMessage} />
      )}
    </Box>
  )
}

export default FormFieldSelect
