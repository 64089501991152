import React from "react"
import { Link as GatsbyLink } from "gatsby"

const resolveLink = to => {
  if (to.type === "bin") {
    return "/bins/"
  }
  if (to.type === "service") {
    return "/services/" + (to.slug || to.uid) + "/"
  }
  if (to.type === "blog_post") {
    return "/blog/" + to.uid + "/"
  }
  if (to.type === "area_of_operation") {
    return "/areas/" + (to.slug || to.uid) + "/"
  }
  return "/" + ((to.slug === "-" ? to.uid : to.slug) || to.uid) + "/"
}

function Link({ children, to, ...rest }) {
  if (to) {
    if (
      to.url &&
      (to.url.indexOf("http") !== -1 ||
        to.url.indexOf("mailto:") !== -1 ||
        to.url.indexOf("tel:") !== -1)
    ) {
      return (
        <a
          children={children}
          href={to.url}
          rel="noopener noreferrer"
          target="_blank"
          {...rest}
        />
      )
    } else {
      if (!to.url) {
        return <GatsbyLink children={children} to={resolveLink(to)} {...rest} />
      } else {
        return <GatsbyLink children={children} to={to.url} {...rest} />
      }
    }
  } else {
    return null
  }
}

export default Link
