import React from "react"

import { Text } from "../../typography"

const FormFieldHint = ({ hint, ...rest }) => (
  <Text
    data-component-id="formField.hint"
    as="p"
    size={300}
    mt="spacing.2"
    color="alt"
    {...rest}
  >
    {hint}
  </Text>
)

export default FormFieldHint
