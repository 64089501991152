import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby"
import { mdiTwitter, mdiInstagram, mdiFacebook, mdiLinkedin } from "@mdi/js"

import {
  Box,
  Divider,
  Flex,
  FlexGrid,
  Heading,
  IconButton,
  Link,
  Text,
  Wrapper,
} from "components"

const NavItem = ({ label, to }) => (
  <Text
    as="li"
    color="alt"
    mb="spacing.2"
    css={{ ":last-of-type": { marginBottom: 0 } }}
  >
    <Link to={to} children={label} />
  </Text>
)

function Footer() {
  const { footer } = useStaticQuery(graphql`
    {
      footer: prismicFooter {
        data {
          contact_prompt {
            html
          }
          contact_link_text
          logo {
            fluid(maxWidth: 256) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          facebook {
            url
          }
          instagram {
            url
          }
          twitter {
            url
          }
          linkedin {
            url
          }
          links {
            label
            internal_link {
              type
              slug
              uid
            }
            external_link {
              url
            }
          }
          company_links {
            label
            internal_link {
              type
              slug
              uid
            }
            external_link {
              url
            }
          }
          area_links {
            label
            internal_link {
              type
              slug
              uid
            }
            external_link {
              url
            }
          }
          badges {
            link {
              url
            }
            badge {
              fluid(maxWidth: 128) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
          }
        }
      }
    }
  `)

  return (
    <Box data-component-id="footer" as="footer" bg="background">
      <Divider />

      {/* Top */}
      <Wrapper pb={0}>
        <FlexGrid
          content={[
            {
              // Contact
              width: ["100%", null, null, "25%"],
              children: (
                <Box>
                  {footer.data.contact_prompt &&
                    footer.data.contact_prompt.html && (
                      <Heading
                        size={800}
                        fontWeight={400}
                        dangerouslySetInnerHTML={{
                          __html: footer.data.contact_prompt.html,
                        }}
                        css={{
                          p: {
                            margin: 0,
                          },
                        }}
                      />
                    )}
                  {footer.data.contact_link_text && (
                    <Text size={600} mt="layout.2" color="alt">
                      <GatsbyLink
                        children={footer.data.contact_link_text}
                        to="/contact/"
                      />
                    </Text>
                  )}
                </Box>
              ),
            },
            {
              // Navigation
              width: ["100%", null, null, "75%"],
              children: (
                <Box>
                  <FlexGrid
                    content={[
                      {
                        // Service links
                        width: "33.333%",
                        children: (
                          <Box>
                            <Heading size={700} pt="spacing.4" mb="layout.2">
                              Services
                            </Heading>
                            <Box
                              as="ul"
                              m={0}
                              p={0}
                              css={{ listStyle: "none" }}
                            >
                              {footer.data.links &&
                                footer.data.links.map(
                                  (
                                    { label, internal_link, external_link },
                                    index
                                  ) => (
                                    <NavItem
                                      label={label}
                                      to={
                                        internal_link
                                          ? internal_link
                                          : external_link.url
                                      }
                                      key={"servicesLink" + index}
                                    />
                                  )
                                )}
                            </Box>
                          </Box>
                        ),
                      },
                      {
                        // Company links
                        width: "33.333%",
                        children: (
                          <Box>
                            <Heading size={700} pt="spacing.4" mb="layout.2">
                              Company
                            </Heading>
                            <Box
                              as="ul"
                              m={0}
                              p={0}
                              css={{ listStyle: "none" }}
                            >
                              {footer.data.company_links &&
                                footer.data.company_links.map(
                                  (
                                    { label, internal_link, external_link },
                                    index
                                  ) => (
                                    <NavItem
                                      label={label}
                                      to={
                                        internal_link
                                          ? internal_link
                                          : external_link.url
                                      }
                                      key={"companyLink" + index}
                                    />
                                  )
                                )}
                            </Box>
                          </Box>
                        ),
                      },
                      {
                        // Area links
                        width: "33.333%",
                        children: (
                          <Box>
                            <Heading size={700} pt="spacing.4" mb="layout.2">
                              Areas
                            </Heading>
                            <Box
                              as="ul"
                              m={0}
                              p={0}
                              css={{ listStyle: "none" }}
                            >
                              {footer.data.area_links &&
                                footer.data.area_links.map(
                                  (
                                    { label, internal_link, external_link },
                                    index
                                  ) => (
                                    <NavItem
                                      label={label}
                                      to={
                                        internal_link
                                          ? internal_link
                                          : external_link.url
                                      }
                                      key={"areaLink" + index}
                                    />
                                  )
                                )}
                            </Box>
                          </Box>
                        ),
                      },
                    ]}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Wrapper>

      {/* Bottom */}
      <Wrapper pb={["layout.6", "layout.8"]}>
        <FlexGrid
          content={[
            {
              // Copyright
              order: [1, -1],
              width: ["100%", "33.333%", null, "50%"],
              children: (
                <Box>
                  <GatsbyLink to="/">
                    {footer.data.logo && footer.data.logo.fluid && (
                      <Image
                        fluid={footer.data.logo.fluid}
                        alt={footer.data.logo.alt}
                        objectFit="contain"
                        objectPosition="left"
                        style={{
                          width: "auto",
                          height: 32,
                        }}
                      />
                    )}
                  </GatsbyLink>
                  <Text size={300} mt="layout.1" color="alt">
                    © {new Date().getFullYear()} BluPlanet Recycling Inc.
                  </Text>
                  <Text size={300} color="alt">
                    <Box
                      as="a"
                      href="https://builtbyfield.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website built by Field.
                    </Box>
                  </Text>
                </Box>
              ),
            },
            {
              width: ["100%", "66.667%", null, "50%"],
              children: (
                <Box>
                  <FlexGrid
                    content={[
                      {
                        // Social media accounts
                        width: ["100%", "50%"],
                        children: (
                          <Box>
                            <Heading size={700} mb="layout.1">
                              Follow us
                            </Heading>
                            {/* Social media links */}
                            <Flex ml="-12px">
                              {footer.data.facebook &&
                                footer.data.facebook.url && (
                                  <IconButton
                                    as="a"
                                    aria-label="Facebook"
                                    href={footer.data.facebook.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    icon={mdiFacebook}
                                    appearance="minimal"
                                    height={48}
                                  />
                                )}
                              {footer.data.twitter &&
                                footer.data.twitter.url && (
                                  <IconButton
                                    as="a"
                                    aria-label="Twitter"
                                    href={footer.data.twitter.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    icon={mdiTwitter}
                                    appearance="minimal"
                                    height={48}
                                  />
                                )}
                              {footer.data.instagram &&
                                footer.data.instagram.url && (
                                  <IconButton
                                    as="a"
                                    aria-label="Instagram"
                                    href={footer.data.instagram.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    icon={mdiInstagram}
                                    appearance="minimal"
                                    height={48}
                                  />
                                )}
                              {footer.data.linkedin &&
                                footer.data.linkedin.url && (
                                  <IconButton
                                    as="a"
                                    aria-label="LinkedIn"
                                    href={footer.data.linkedin.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    icon={mdiLinkedin}
                                    appearance="minimal"
                                    height={48}
                                  />
                                )}
                            </Flex>
                          </Box>
                        ),
                      },
                      {
                        // Certifications
                        width: ["100%", "50%"],
                        children: (
                          <Box>
                            <Flex>
                              {footer.data.badges &&
                                footer.data.badges.map(
                                  ({ badge, link }, index) => (
                                    <a
                                      href={link && link.url}
                                      key={"footerBadge" + index}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Image
                                        fluid={badge && badge.fluid}
                                        alt={badge && badge.alt}
                                        objectFit="contain"
                                        objectPosition="center"
                                        style={{
                                          width: 80,
                                          height: 80,
                                        }}
                                      />
                                    </a>
                                  )
                                )}
                            </Flex>
                          </Box>
                        ),
                      },
                    ]}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Wrapper>
    </Box>
  )
}

export default Footer
