import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import { mdiArrowTopRight } from "@mdi/js"

// Project components
import { AspectRatio, ColorMode, Flex, Heading, Icon } from "components"

const ServiceCard = ({ image, title, color, to }) => (
  <ColorMode mode="dark">
    <Flex>
      <Flex
        flexDirection="column"
        flex={1}
        p={["layout.1", "layout.2"]}
        borderRadius={8}
        bg={color}
      >
        <Link to={to}>
          <AspectRatio ratio="4:3" borderRadius={4} bg="wash">
            {image && image.fluid && (
              <Image
                fluid={image.fluid}
                alt={image.alt}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                objectFit="cover"
                objectPosition="bottom left"
              />
            )}
          </AspectRatio>
        </Link>

        <Flex
          justifyContent="space-between"
          alignItems="center"
          pt="layout.1"
          pb={["layout.2", "layout.4"]}
        >
          <Heading size={700}>
            <Link to={to}>{title}</Link>
          </Heading>
          <Link to={to}>
            <Icon symbol={mdiArrowTopRight} />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  </ColorMode>
)

export default ServiceCard
