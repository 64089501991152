import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"
import { mdiStar } from "@mdi/js"

import {
  AspectRatio,
  Box,
  ColorMode,
  Flex,
  Heading,
  Icon,
  Paragraph,
  Text,
} from "components"

const Badge = ({ title, color }) => (
  <Flex data-component-id="bin.badge" alignItems="center">
    <Box mr="spacing.2">
      <Box width="16px" height="16px" borderRadius={9999} bg={color} />
    </Box>
    <Heading size={100}>{title}</Heading>
  </Flex>
)

const Bin = ({
  description,
  dimensions,
  image,
  name,
  greatFor,
  serviceTypes,
}) => (
  <Box data-component-id="bin">
    {/* Image */}
    <AspectRatio ratio="4:3" bg="wash">
      <ColorMode mode="dark">
        {image && image.fluid && (
          <Image
            fluid={image.fluid}
            alt={image.alt}
            objectFit="cover"
            objectPosition="center"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: 4,
            }}
          />
        )}
        {greatFor && (
          <Flex
            position="absolute"
            top={[0, "layout.2"]}
            left="-8px"
            alignItems="center"
            pr="spacing.3"
            pl="spacing.2"
            py="spacing.1"
            borderRadius={9999}
            bg="background"
            boxShadow="elevations.1"
            css={{ transform: ["translateY(-50%)", null] }}
          >
            <Icon symbol={mdiStar} size={12} mr="spacing.2" color="text" />
            <Heading size={100} pb="1px">
              Great for{" "}
              <Box as="span" fontWeight="bold">
                {greatFor}
              </Box>
            </Heading>
          </Flex>
        )}
      </ColorMode>
    </AspectRatio>

    {/* Info */}
    {(name || description) && (
      <Box pt="layout.2">
        {name && <Heading size={700}>{name}</Heading>}
        {description && (
          <Paragraph size={300} mt="layout.1">
            {description}
          </Paragraph>
        )}
      </Box>
    )}

    {/* Dimensions */}
    {dimensions && (
      <Box pt="layout.1">
        <Text size={300}>
          <strong>
            W {dimensions.width} × D {dimensions.depth} × H {dimensions.height}
          </strong>
        </Text>
      </Box>
    )}

    {/* Badges */}
    {/* Do not show section if no relevant props are set */}
    {serviceTypes && serviceTypes.length > 0 && (
      <Flex
        flexWrap="wrap"
        m="-4px"
        pt="layout.1"
        css={css({
          "> *": {
            p: "4px",
          },
        })}
      >
        {serviceTypes.map(({ service_type }) => (
          <Badge
            title={
              service_type.document &&
              service_type.document.data.name &&
              service_type.document.data.name.text
            }
            color={service_type.document && service_type.document.data.color}
          />
        ))}
      </Flex>
    )}
  </Box>
)

export default Bin
