import React from "react"
import Image from "gatsby-image/withIEPolyfill"

// Project components
import { AspectRatio, Box, Flex, Heading, Paragraph } from "components"

const CommunityCard = ({ title, description, image, link, ...rest }) => (
  <Flex
    data-component-id="communityCard"
    flex={1}
    px={["layout.2", "layout.4"]}
    py={["layout.3", "layout.4"]}
    borderRadius="15px"
    bg="wash"
    {...rest}
  >
    <Flex flex={1} flexDirection="column">
      {/* TITLE */}
      {title && (
        <Heading size={500} mb="layout.2" color="brand.primary">
          {title}
        </Heading>
      )}
      {/* TEXT */}
      <Paragraph size={500}>{description}</Paragraph>

      {/* IMAGE */}
      <Box
        width="100%"
        maxWidth={[128, 160]}
        mt="auto"
        pt={["layout.4", "layout.7"]}
      >
        <Box
          as="a"
          href={link && link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AspectRatio ratio="3:2">
            {image && image.fluid && (
              <Image
                fluid={image.fluid}
                alt={image.alt}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                objectFit="contain"
                objectPosition="bottom left"
              />
            )}
          </AspectRatio>
        </Box>
      </Box>
    </Flex>
  </Flex>
)

export default CommunityCard
