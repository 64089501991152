import React, { useState } from "react"
import { Link } from "gatsby"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"
import { Global } from "@emotion/react"
import css from "@styled-system/css"
import { mdiClose } from "@mdi/js"
import Image from "gatsby-image/withIEPolyfill"

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  RichText,
} from "components"

function GlobalStyles() {
  return (
    <Global
      styles={css({
        ".popUp[data-reach-dialog-overlay]": {
          zIndex: 9999,
          bg: "rgba(0, 0, 0, 0.5)",

          "[data-reach-dialog-content]": {
            width: "95%",
            maxWidth: 640,
            m: "20vh auto",
            p: 0,
            borderRadius: 15,
            overflow: "hidden",
            boxShadow: "elevations.4",
          },
        },
      })}
    />
  )
}

function PopUp({
  visibility,
  image,
  heading,
  content,
  buttonLabel,
  buttonLink,
}) {
  const [showDialog, setShowDialog] = useState(true)
  // const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  const VISIBILITY =
    visibility === "Show" ? true : visibility !== "Hide" ? false : false
  const IMAGE = image && image.fluid
  const IMAGE_ALT = image && image.alt
  const HEADING = heading && heading.text
  const CONTENT = content
  const BUTTON_LABEL = buttonLabel
  const BUTTON_LINK = buttonLink

  return (
    <>
      <GlobalStyles />

      {VISIBILITY && (
        <DialogOverlay className="popUp" isOpen={showDialog}>
          <DialogContent>
            <Box position="relative">
              <Box
                position="absolute"
                top={["layout.2", "layout.3"]}
                right={["layout.2", "layout.3"]}
                zIndex={1}
              >
                <IconButton
                  icon={mdiClose}
                  intent="default"
                  appearance="primary"
                  height={[32, 40]}
                  onClick={close}
                />
              </Box>

              {/* Optional image */}
              {IMAGE && (
                <Box>
                  <AspectRatio ratio="21:9" bg="wash">
                    <Image
                      fluid={IMAGE}
                      alt={IMAGE_ALT}
                      objectFit="cover"
                      objectPosition="center"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                </Box>
              )}

              <Box p={["layout.2", "layout.3"]}>
                {HEADING && (
                  <Box pr="40px">
                    <Heading children={HEADING} size={800} />
                  </Box>
                )}

                {/* Use richtext over paragraph */}
                {CONTENT && <RichText content={CONTENT} mt="layout.2" />}

                <Flex justifyContent="flex-end" mt={["layout.4", "layout.5"]}>
                  <Button
                    children="Close"
                    intent="default"
                    appearance="ghost"
                    height={[40, 48]}
                    onClick={close}
                  />
                  {/* Optional button */}
                  {BUTTON_LABEL && BUTTON_LINK && BUTTON_LINK.url && (
                    <Button
                      as={BUTTON_LINK.url.startsWith("http") ? "a" : Link}
                      href={BUTTON_LINK.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      to={BUTTON_LINK.url}
                      children={BUTTON_LABEL}
                      intent="secondary"
                      appearance="primary"
                      height={[40, 48]}
                      ml="layout.1"
                    />
                  )}
                </Flex>
              </Box>
            </Box>
          </DialogContent>
        </DialogOverlay>
      )}
    </>
  )
}

export default PopUp
