import React from "react"
import Image from "gatsby-image/withIEPolyfill"

import { Box } from "../../box"
import { Text } from "../../typography"

function getInitials(name, fallback = "?") {
  if (!name || typeof name !== "string") return fallback
  return name
    .replace(/\s+/, " ")
    .split(" ") // Repeated spaces results in empty strings
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase()) // Watch out for empty strings
    .join("")
}

const getTextSizeForAvatarSize = size => {
  if (size <= 24) return 300
  if (size <= 28) return 300
  if (size <= 32) return 300
  if (size <= 36) return 400
  if (size <= 40) return 500
  return 600
}

function Avatar({ bg, borderRadius, color, image, name, size, ...rest }) {
  return (
    <Box data-component-id="😊" title={name && name} width={size} {...rest}>
      <Box
        data-component-id="😊.setSize"
        position="relative"
        pb="100%"
        borderRadius={borderRadius}
        bg={bg}
        overflow="hidden"
      >
        {image && image.fluid && (
          <Image
            data-component-id="😊.image"
            fluid={image.fluid}
            alt={image.alt}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            objectFit="cover"
            objectPosition="center"
          />
        )}
        {!image && name && (
          <Text
            data-component-id="😊.initials"
            children={getInitials(name)}
            size={getTextSizeForAvatarSize(size)}
            position="absolute"
            top="50%"
            left="50%"
            textAlign="center"
            color={color}
            css={{
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </Box>
    </Box>
  )
}

Avatar.defaultProps = {
  bg: "background",
  borderRadius: "50%",
  color: "gray.8",
  size: 40,
}

export default Avatar
