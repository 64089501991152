import React from "react"
import { mdiMenuDown } from "@mdi/js"
import { rgba } from "polished"
import { useTheme } from "@emotion/react"

import { Box } from "../../box"
import { Icon } from "../../icon"
import { Text } from "../../typography"

const getBorderRadiusForControlHeight = height => {
  if (height <= 40) return "3px"
  return "5px"
}

const getTextSizeForControlHeight = height => {
  if (height <= 24) return 300
  if (height <= 28) return 300
  if (height <= 32) return 400
  if (height <= 36) return 400
  if (height <= 40) return 400
  return 500
}

const getIconSizeForButton = height => {
  if (height <= 28) return 16
  if (height <= 32) return 16
  if (height <= 40) return 16
  if (height <= 48) return 18
  return 20
}

const Select = ({ name, required, children, height, id, round, ...rest }) => {
  const theme = useTheme()

  const br = getBorderRadiusForControlHeight(height)
  const is = getIconSizeForButton(height)
  const ts = getTextSizeForControlHeight(height)

  return (
    <Box
      data-component-id="select"
      position="relative"
      display="inline-flex"
      {...rest}
    >
      <Text
        data-component-id="select.control"
        as="select"
        id={id}
        size={ts}
        width="100%"
        height={height}
        m={0}
        pl={Math.round(height / 4) + "px"}
        pr={Math.round(height / 1.5) + "px"}
        py={0}
        border={0}
        borderRadius={round ? 9999 : br}
        fontWeight={400}
        lineHeight={height + "px"}
        bg="background"
        boxShadow={`0 0 0 1px inset ${rgba(
          theme.colors.text,
          0.1
        )}, 0 2px 2px -2px ${rgba(theme.colors.text, 0.3)}`}
        css={{
          userSelect: "none",
          appearance: "none",
          cursor: "pointer",
        }}
        name={name}
        required={required}
      >
        {children}
      </Text>
      <Icon
        data-component-id="select.icon"
        as="span"
        symbol={mdiMenuDown}
        size={is}
        position="absolute"
        top="50%"
        right={Math.round((height / 1.5 - is / 2) / 3) + "px"}
        color="inherit"
        css={{
          pointerEvents: "none",
          transform: "translateY(-50%)",
        }}
      />
    </Box>
  )
}

Select.defaultProps = {
  height: 40,
  size: 300,
}

export default Select
