import React from "react"

import { Text } from "../../typography"

const FormFieldLabel = ({ label, ...rest }) => (
  <Text
    data-component-id="formField.label"
    as="label"
    size={300}
    display="block"
    mb="spacing.1"
    color="text"
    {...rest}
  >
    {label}
  </Text>
)

export default FormFieldLabel
