import React from "react"
import { useTheme } from "@emotion/react"
import TextareaAutosize from "react-textarea-autosize"
import css from "@styled-system/css"
import { rgba } from "polished"

import { Box } from "../../box"
import { Text } from "../../typography"

const getBorderRadiusForControlHeight = height => {
  if (height <= 40) return "3px"
  return "5px"
}

const getTextSizeForControlHeight = height => {
  if (height <= 32) return 400
  if (height <= 40) return 400
  if (height <= 48) return 600
  return 600
}

const Textarea = ({ bg, height, rows, ...rest }) => {
  const theme = useTheme()
  const br = getBorderRadiusForControlHeight(height)
  const ts = getTextSizeForControlHeight(height)
  const p = Math.round(height / 4)

  return (
    <Box
      data-component-id="textarea"
      borderRadius={br}
      bg={bg}
      overflow="hidden"
      boxShadow={`
        ${rgba(theme.colors.text, 0.1)} 
        0 0 0 1px inset, 
        ${rgba(theme.colors.text, 0.1)}
        0 1px 2px inset
    `}
    >
      <Text
        data-component-id="textarea.field"
        as={TextareaAutosize}
        rows={rows}
        size={ts}
        display="block"
        width="100%"
        height={height}
        m={0}
        px={p + "px"}
        py="10px"
        border={0}
        fontFamily="standard"
        color="text"
        bg="transparent"
        css={css({
          fontSize: 16,
          minHeight: 128,
          resize: "none",
          "::placeholder": {
            color: "text",
            opacity: 1 / 3,
          },
          ":focus": {
            outline: 0,
          },
        })}
        {...rest}
      />
    </Box>
  )
}

Textarea.defaultProps = {
  bg: "background",
  height: 40,
}

export default Textarea
