import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import css from "@styled-system/css"

import { Heading } from "components"

function AccordionGroup({ id, items, onChange, preExpanded, ...rest }) {
  return (
    <Accordion allowZeroExpanded={true} preExpanded={preExpanded} {...rest}>
      {items.map((item, index) => (
        <AccordionItem
          key={"accordionGroup" + index}
          uuid={"accordionGroup" + index}
          id={item.id}
          css={css({
            py: ["layout.2", null, null, "layout.4"],
            borderTop: "1px solid",
            borderColor: "border",
            "&:last-of-type": {
              borderBottom: "1px solid",
              borderColor: "border",
            },
          })}
          onClick={() => {
            if (onChange) {
              onChange(item.object)
            }
          }}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              css={css({
                display: "flex",
                cursor: "pointer",
                "&:focus": { outline: 0 },
                '&[aria-expanded="true"]': {
                  ".control": {
                    "> span:last-of-type": {
                      transform:
                        "translate(-50%, -50%) rotate(90deg) scaleX(0)",
                    },
                  },
                },
              })}
            >
              <Heading
                children={item.heading}
                as="span"
                size={800}
                flex={1}
                fontWeight="normal"
              />
              <span
                className="control"
                css={css({
                  position: "relative",
                  display: "block",
                  width: [16, null, null, 24],
                  height: [16, null, null, 24],
                  mt: ["4px", null, null, "8px"],
                  ml: 3,
                  verticalAlign: "middle",
                })}
              >
                <span
                  css={css({
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    height: "2px",
                    bg: "text",
                    transform: "translate(-50%, -50%)",
                  })}
                />
                <span
                  css={css({
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    height: "2px",
                    bg: "text",
                    transform: "translate(-50%, -50%) rotate(90deg)",
                    transition: "transform 200ms ease-out",
                  })}
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            css={css({ pt: ["layout.2", null, null, "layout.4"] })}
          >
            {item.children}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

AccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: [],
}

export default AccordionGroup
