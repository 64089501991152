import React from "react"
import { nanoid } from "nanoid"

import { Box } from "../../box"
import {
  FormFieldDescription,
  FormFieldHint,
  FormFieldLabel,
  FormFieldValidationMessage,
} from "../../formField"

import Input from "./Input"

const FormFieldInput = ({
  description,
  hint,
  inputProps,
  label,
  validationMessage,
  ...rest
}) => {
  const uid = nanoid()

  return (
    <Box data-component-id="formFieldInput" {...rest}>
      {label && <FormFieldLabel label={label} />}
      {description && <FormFieldDescription description={description} />}
      <Input id={uid} {...inputProps} />
      {hint && <FormFieldHint hint={hint} />}
      {validationMessage && (
        <FormFieldValidationMessage validationMessage={validationMessage} />
      )}
    </Box>
  )
}

export default FormFieldInput
