import React from "react"
import { useTheme } from "@emotion/react"

import { Icon, Text } from "components"

import buttonStyles from "./buttonStyles"

const getTextSizeForControlHeight = height => {
  if (height <= 24) return 300
  if (height <= 28) return 300
  if (height <= 32) return 300
  if (height <= 36) return 400
  if (height <= 40) return 400
  return 500
}

const getIconSizeForButton = height => {
  if (height <= 28) return 12
  if (height <= 32) return 12
  if (height <= 40) return 16
  if (height <= 48) return 18
  return 20
}

function Button({
  appearance,
  children,
  height,
  iconAfter,
  iconBefore,
  intent,
  ...rest
}) {
  let is = getIconSizeForButton(height)
  let ts = getTextSizeForControlHeight(height)

  return (
    <Text
      data-component-id="buttons.button"
      size={ts}
      position="relative"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      height={height}
      m={0}
      px="1em"
      py={0}
      border={0}
      borderRadius={0}
      fontFamily="standard"
      fontWeight="bold"
      lineHeight={height + "px"}
      overflow="hidden"
      css={{
        ...buttonStyles(appearance, intent, useTheme()),
        userSelect: "none",
        appearance: "none",
        cursor: "pointer",
      }}
      {...rest}
    >
      {iconBefore && (
        <Icon
          data-component-id="buttons.button.iconBefore"
          symbol={iconBefore}
          size={is}
          mr={Math.round(is * 0.3) + "px"}
          ml={"-" + Math.round(is * 0.3) + "px"}
          color="inherit"
        />
      )}
      {children}
      {iconAfter && (
        <Icon
          data-component-id="buttons.button.iconAfter"
          symbol={iconAfter}
          size={is}
          mr={"-" + Math.round(is * 0.3) + "px"}
          ml={Math.round(is * 0.3) + "px"}
          color="inherit"
        />
      )}
    </Text>
  )
}

Button.defaultProps = {
  appearance: "default",
  as: "button",
  borderRadius: 9999,
  height: 48,
  intent: "default",
}

export default Button
