function buttonStyles(appearance, intent, theme) {
  const intents = {
    default: {
      base: theme.colors.text,
      contrast: theme.colors.background,
    },
    primary: {
      base: theme.colors.brand.secondary,
      contrast: "#fff",
    },
    secondary: {
      base: theme.colors.brand.primary,
      contrast: "#fff",
    },
  }

  switch (appearance) {
    case "primary":
      return {
        color: intents[intent].contrast,
        backgroundColor: intents[intent].base,
        boxShadow: theme.shadows.elevations[2],
      }
    case "minimal":
      return {
        color: intents[intent].base,
        backgroundColor: "transparent",
      }
    case "ghost":
      return {
        color: intents[intent].base,
        backgroundColor: theme.colors.wash,
      }
    default:
      return {
        color: intents[intent].base,
        backgroundColor: intents[intent].contrast,
      }
  }
}

export default buttonStyles
