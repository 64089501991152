import React from "react"
import Image from "gatsby-image/withIEPolyfill"

import { Box, FlexGrid, Wrapper } from "components"

const Split = ({ children, image, ...rest }) => (
  <Wrapper
    data-component-id="split"
    minHeight={["none", null, null, "calc(var(--vh, 1vh) * 100)"]}
    pt={0}
    pb={0}
    overflow="hidden"
    {...rest}
  >
    <FlexGrid
      content={[
        {
          // Content
          width: ["100%", null, null, "50%"],
          children: (
            <Box
              pt={[0, null, null, "calc(32px + 48px + 128px)"]}
              pb={["layout.4", null, null, "layout.8"]}
              pr={[0, null, null, "64px"]}
            >
              {children}
            </Box>
          ),
        },
        {
          // Image
          order: [-1, null, null, 0],
          width: ["100%", null, null, "50%"],
          children: (
            <Box position="relative" height="100%">
              <Box
                position={["relative", null, null, "absolute"]}
                top={0}
                left={0}
                bottom={0}
                width={["100vw", null, null, "calc(50vw - 16px)"]}
                ml={[-24, -64, null, 0]}
                bg="wash"
                css={{
                  //IE11 Fallback
                  // minHeight: ["50vh", null, null, "100vh"],
                  minHeight: [
                    "calc(var(--vh, 1vh) * 50)",
                    null,
                    null,
                    "calc(var(--vh, 1vh) * 100)",
                  ],
                }}
              >
                {image && image.fluid && (
                  <Image
                    fluid={image.fluid}
                    alt={image.alt}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    objectFit="cover"
                    objectPosition="center"
                  />
                )}
              </Box>
            </Box>
          ),
        },
      ]}
    />
  </Wrapper>
)

export default Split
