import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"

// Project components
import { AspectRatio, Box, Flex, Heading, RichText } from "components"

const Item = ({ image, name, where, cardColor }) => (
  <Flex>
    <Flex
      flexDirection="column"
      flex={1}
      p={["layout.1", "layout.2"]}
      borderRadius={8}
      bg={cardColor.background}
    >
      <AspectRatio
        ratio="4:3"
        borderRadius={4}
        bg="background"
        css={css({ overflow: "hidden" })}
      >
        {image && image.fluid && (
          <Image
            fluid={image.fluid}
            alt={image.alt}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            objectFit="contain"
            objectPosition="center"
          />
        )}
      </AspectRatio>
      {name && (
        <Box py="layout.1">
          <Heading size={700} color={cardColor.heading}>
            {name}
          </Heading>
        </Box>
      )}
      {where && (
        <Box pb="layout.2">
          <RichText
            content={{
              html: where,
            }}
            style={{ whiteSpace: "pre-line" }}
            size={500}
            color={cardColor.text}
          />
        </Box>
      )}
    </Flex>
  </Flex>
)

export default Item
