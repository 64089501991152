import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import { mdiArrowRight } from "@mdi/js"

import {
  AspectRatio,
  Box,
  Button,
  FlexGrid,
  Heading,
  Text,
  Wrapper,
} from "components"

const NextPage = ({ buttonLabel, buttonTo, image, title, ...rest }) => (
  <>
    {/* We only render the component if it has props set */}
    {(buttonLabel || buttonTo || title) && (
      <Wrapper data-component-id="nextPage" {...rest}>
        <FlexGrid
          alignItems="center"
          content={[
            {
              width: ["100%", "50%"],
              children: (
                <AspectRatio ratio="4:3" bg="wash">
                  {image && image.fluid && (
                    <Image
                      fluid={image.fluid}
                      alt={image.alt}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      objectFit="cover"
                      objectPosition="center"
                    />
                  )}
                </AspectRatio>
              ),
            },
            {
              width: ["100%", "50%"],
              children: (
                <Box pl={[0, "layout.6"]}>
                  <Text size={500}>Next</Text>
                  {title && (
                    <Heading size={800} mt="layout.2">
                      {title}
                    </Heading>
                  )}
                  {buttonLabel && buttonTo && (
                    <Button
                      as={Link}
                      to={buttonTo}
                      iconAfter={mdiArrowRight}
                      intent="primary"
                      appearance="primary"
                      mt="layout.4"
                    >
                      {buttonLabel}
                    </Button>
                  )}
                </Box>
              ),
            },
          ]}
        />
      </Wrapper>
    )}
  </>
)

export default NextPage
