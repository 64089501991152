import React from "react"
import css from "@styled-system/css"
import { fluidRange } from "polished"

import { Heading } from "components"

const Flex = ({ ...rest }) => (
  <Heading
    data-component-id="fluidText"
    size={900}
    fontWeight="normal"
    color="brand.primary"
    css={css({
      ...fluidRange(
        {
          prop: "fontSize",
          fromSize: "48px",
          toSize: "128px",
        },
        "420px",
        "1056px"
      ),
      lineHeight: "0.875 !important",
    })}
    {...rest}
  />
)

export default Flex
