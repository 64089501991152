import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@emotion/react"
import { AnimatePresence, motion } from "framer-motion"

import { Footer, GlobalStyles, Header, Theme } from "components"

import "./fonts.css"

const DURATION = 0.5

function Layout({ location, children }) {
  useEffect(() => {
    // Set static viewport height unit on initial load
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <>
        <GlobalStyles />
        <Header location={location} />
        {/* <AnimatePresence>
          <motion.div
            key={location.pathname}
            variants={{
              initial: {
                y: "100%",
              },
              enter: {
                y: "-100%",
                transition: {
                  duration: DURATION * 2,
                  delay: 0.2,
                },
              },
              exit: {
                y: "-100%",
                transition: { DURATION: 0 },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
            style={{ zIndex: 4 }}
            css={css({
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              bg: "brand.primary",
              zIndex: 4,
            })}
          />
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            key={location.pathname}
            variants={{
              initial: {
                y: "100%",
              },
              enter: {
                y: "-100%",
                transition: {
                  duration: DURATION * 2,
                  delay: 0.45,
                },
              },
              exit: {
                y: "-100%",
                transition: { DURATION: 0 },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
            style={{ zIndex: 6 }}
            css={css({
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              bg: "brand.secondary",
              zIndex: 6,
            })}
          />
        </AnimatePresence> */}
        <AnimatePresence>
          <motion.main
            key={location.pathname}
            variants={{
              initial: {
                opacity: 0,
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: DURATION,
                  delay: DURATION,
                  when: "beforeChildren",
                },
              },
              exit: {
                opacity: 0,
                transition: { duration: DURATION },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
            style={{ zIndex: 0 }}
          >
            {children}
          </motion.main>
        </AnimatePresence>
        <Footer />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
