import React from "react"

import { Text } from "../../typography"

const FormFieldDescription = ({ description, ...rest }) => (
  <Text
    data-component-id="formField.description"
    as="p"
    size={300}
    mb="spacing.2"
    color="alt"
    {...rest}
  >
    {description}
  </Text>
)

export default FormFieldDescription
