import React from "react"

import { Box, Wrapper } from "components"

const Divider = ({ ...rest }) => (
  <Wrapper data-component-id="divider" {...rest}>
    <Box
      data-component-id="divider.line"
      as="hr"
      width="100%"
      height="1px"
      m={0}
      border={0}
      bg="border"
    />
  </Wrapper>
)

export default Divider
