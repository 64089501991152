import React from "react"

import { Box } from "components"

const Wrapper = ({ children, maxWidth, ...rest }) => (
  <Box data-component-id="wrapper" as="section" {...rest}>
    <Box
      data-component-id="wrapper.inner"
      width="100%"
      maxWidth={maxWidth}
      mx="auto"
    >
      {children}
    </Box>
  </Box>
)

Wrapper.defaultProps = {
  maxWidth: 1056,
  pt: ["layout.4", "layout.5", "layout.6"],
  pb: ["layout.4", "layout.5", "layout.6"],
  px: ["layout.3", "layout.6"],
  bg: "background",
}

export default Wrapper
